<template>
  <div>
    <div v-if="authenticated && !authorizationFailed">
      <b-navbar v-if="!hideNavbar" />
      <div class="container" style="min-width: 992px">
        <b-alert v-if="(isDevelopment || isTest) && showExternalWarning" class="danger d-flex align-items-center mt-3">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
            <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
          </svg>
          <div>
            <b>Warning!</b> Functionality on this page makes modifications to databases other than HermesTest - changes may affect live systems
          </div>
        </b-alert>
        <router-view @show-external-warning="showExternalWarning = true" @hide-navbar="hideNavbar = true" />
        <b-card v-if="notFound">
          <template v-slot:title>Not Found</template> 
          <template v-slot:body>
            <p>The page you are looking for might have been removed, had its name changed or is temporarily unavailable.</p>
            <b-button class="secondary me-1" @click="goBack">Back</b-button>
            <b-button @click="goHome">Home</b-button>
          </template> 
        </b-card>
        <div class="border-top mb-3 mt-3"></div>
        <div class="row mb-3">
          <div class="col-6">&copy; 118 Group {{ $moment().format("YYYY") }}</div>
          <div class="col-6 text-end">Version {{ version }} (API version {{ apiVersion ?? 'unknown' }})</div>
        </div>
      </div>
    </div>
    <div class="toast-container">
      <b-toast v-for="toast in toasts" :key="toast.id" :toast="toast" @close="closeToast" />
    </div>
    <b-modal v-for="modal in modals" :key="modal.id" @close="closeModal(modal.id)">
      <template v-slot:title>{{ modal.title ?? "Notice" }}</template>
      <template v-slot:body>{{ modal.text }}</template>
      <template v-slot:actions>
          <b-button class="secondary" @click="closeModal(modal.id)" v-focus>Close</b-button>
      </template>
    </b-modal>
    <b-modal v-for="error in errors" :key="error.id" @close="closeModal(modal.id)">
      <template v-slot:title>Error</template>
      <template v-slot:body>
        <p>An unhandled exception has occurred - please see details below</p>
        <textarea class="form-control" rows="4" disabled :value="error.text"></textarea>
      </template>
      <template v-slot:actions>
          <b-button class="secondary" @click="closeError(error.id)" v-focus>Close</b-button>
      </template>
    </b-modal>
    <b-modal v-if="apiConnectionFailed" :hide-close="true">
      <template v-slot:title>Error</template>
      <template v-slot:body>Connection to API failed - please try again</template>
      <template v-slot:actions>
          <b-button @click="getApiVersion" v-focus>Retry</b-button>
      </template>
    </b-modal>
    <b-modal v-if="authenticationFailed" :hide-close="true">
      <template v-slot:title>Error</template>
      <template v-slot:body>Connection to authentication server failed - please try again</template>
      <template v-slot:actions>
          <b-button @click="getAuthentication" v-focus>Retry</b-button>
      </template>
    </b-modal>
    <b-modal v-if="authorizationFailed" :hide-close="true">
      <template v-slot:title>Error</template>
      <template v-slot:body>You are not authorized to use this application - please try again</template>
      <template v-slot:actions>
          <b-button @click="logOut" v-focus>Log Out</b-button>
          <b-button @click="getAuthentication" v-focus>Retry</b-button>
      </template>
    </b-modal>
    <div v-if="axiosRequests > 0" class="spinner-mask">
      <div class="spinner-border text-light"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      applicationName: require('../package.json').friendlyName,
      keycloakClient: require('../package.json').name,
      version: require('../package.json').version,
      navigation: require('./navigation.json'),
      modals: [],
      toasts: [],
      errors: [],
      axiosRequests: 0,
      axiosHideSpinnerUrls: [],
      apiConnectionFailed: false,
      apiUrl: '/api',
      apiVersion: null,
      authenticated: null,
      authenticationFailed: false,
      authorizationFailed: false,
      isDevelopment: false,
      isTest: false,
      skipAuthentication: false,
      showExternalWarning: false,
      hideNavbar: false
    }
  },
  computed: {
    notFound() {
      return this.$route.matched.length == 0;
    }
  },
  created() {
    this.isDevelopment = window.location.host.includes("localhost");
    this.isTest = window.location.host.includes("hermes-test");

    if(this.isDevelopment) {
      this.apiUrl = "https://localhost:8443/api";
    }

    this.getAuthentication();
  },
  mounted() {
    var _this = this;

    this.$http.interceptors.request.use(function (config) {
      if (_this.axiosHideSpinnerUrls.filter(url => config.url.includes(url)).length == 0) {
        _this.axiosRequests++;
      }

      return config;
    }, function (error) {
      return Promise.reject(error);
    });

    this.$http.interceptors.response.use(function (response) {
      if (_this.axiosHideSpinnerUrls.filter(url => response.config.url.includes(url)).length == 0) {
        _this.axiosRequests--;
      }
      
      return response;
    }, function (error) {
      _this.axiosRequests--;
      return Promise.reject(error);
    });
  },
  methods: {
    stringNullOrWhitespace(string) {
      return string == null || string.trim() === '';
    },
    goHome() {
      window.location = '/';
    },
    goBack() {
      history.back();
    },
    async getAuthentication() {
      this.getApiVersion();

      if (this.isDevelopment && this.skipAuthentication) {
        this.authenticated = true;
      }

      else {
        if (this.$keycloak.authenticated) {
          this.$keycloak.updateToken(70).then(() => {
            if (!this.$keycloak.realmAccess.roles.includes(this.keycloakClient)) {
              this.authorizationFailed = true;
            }

            else {
              this.authorizationFailed = false;
              this.authenticated = true;
            }
          }).catch(() => {
            this.authenticated = false;
            this.authenticationFailed = true;
          });
        }
        
        else {
          this.$keycloak.init({ onLoad: 'login-required', checkLoginIframe: false }).then((auth) => {
            if (!auth) {
              window.location.reload();
            }
            
            else {
              this.authenticated = true;

              if (!this.$keycloak.realmAccess.roles.includes(this.keycloakClient)) {
                this.authorizationFailed = true;
              }
            }

            setInterval(() => {
              this.$keycloak.updateToken(70).then(() => {
                if (!this.$keycloak.realmAccess.roles.includes(this.keycloakClient)) {
                  this.authorizationFailed = true;
                }
              }).catch(() => {
                this.authenticated = false;
                this.authenticationFailed = true;
              });
            }, 6000);
          }).catch(() => {
            this.authenticated = false;
            this.authenticationFailed = true;
          });
        }
      }
    },
    async getApiVersion() {
      var response = await this.get(this.apiUrl + "/version");

      this.apiVersion = response.success ? response.data : null;
    },
    logOut() {
      var logoutOptions = { redirectUri : window.location.href };
 
      this.$keycloak.logout(logoutOptions).catch((error) => {
        this.showError(error);
      });
    },
    showToast(text, warning = false) {
      var toast = {
        id: this.$guid(),
        text,
        warning,
        time: this.$moment().format("HH:mm:ss")
      };
      
      this.toasts.push(toast);

      if (!warning) {
        setTimeout(() => this.closeToast(toast.id), 2500);
      }
    },
    closeToast(id) {
      this.toasts = this.toasts.filter(t => t.id != id);
    },
    showModal(text, title = null) {
      this.modals.push({
        id: this.$guid(),
        text,
        title
      });
    },
    closeModal(id) {
      this.modals = this.modals.filter(m => m.id != id);
    },
    showError(text) {
      this.errors.push({
        id: this.$guid(),
        text
      });
    },
    closeError(id) {
      this.errors = this.errors.filter(e => e.id != id);
    },
    updateHeaders() {
      if (this.authenticated) {
        this.$http.defaults.headers.common["Authorization"] = "Bearer " +  this.$keycloak.token;
      }
      else
      {
        delete this.$http.defaults.headers.common["Authorization"];
      }
    },
    axiosHideSpinnerUrlsAdd(urlToAdd) {
      this.axiosHideSpinnerUrls.push(urlToAdd);
    },
    axiosHideSpinnerUrlsRemove(urlToRemove) {
      this.axiosHideSpinnerUrls = this.axiosHideSpinnerUrls.filter(url => url != urlToRemove);
    },
    handleResponse(response) {
      this.apiConnectionFailed = false;
      
      if (!response.data.success) {
        if (!response.data.unexpected) {
          this.showModal(response.data.message);
        }
        else {
          this.showError(response.data.message);
        }
      }

      return response.data;
    },
    handleError(error) {
      if (!error.response) {
        this.apiConnectionFailed = true;
      }
      else if (error.response.status == 401 && this.modals.filter(m => m.text == "You must log in to view this page").length == 0) {
        this.showModal("You must log in to view this page");
      }
      else if (error.response.status == 403 && this.modals.filter(m => m.text == "You do not have permission to perform this action").length == 0) {
        this.showModal("You do not have permission to perform this action");
      }
      else {
        this.showError(JSON.stringify(error.response, null, '\t'));
      }

      return {
        success: false
      };
    },
    async get(url, data) {
      try {
        this.updateHeaders();

        var params = data ? {
          params: data,
          paramsSerializer: {
            indexes: null
          }
        } : null;

        return this.handleResponse(await this.$http.get(url, params));
      }
      catch (error) {
        return this.handleError(error);
      }
    },
    async delete(url, data) {
      try {
        this.updateHeaders();

        var params = data ? {
          params: data
        } : null;

        return this.handleResponse(await this.$http.delete(url, params));
      }
      catch (error) {
        return this.handleError(error);
      }
    },
    async post(url, data) {
      try {
        this.updateHeaders();

        return this.handleResponse(await this.$http.post(url, data));
      }
      catch (error) {
        return this.handleError(error);
      }
    },
    async patch(url, data) {
      try {
        this.updateHeaders();

        return this.handleResponse(await this.$http.patch(url, data));
      }
      catch (error) {
        return this.handleError(error);
      }
    }
  }
}
</script>

<style>
@import '@118group/vuecomponents/dist/library.mjs.css';

.is-valid-warn {
  border-color: #ffc107;
  padding-right: calc(1.5em + .75rem);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' fill='%23ffc107' class='bi bi-exclamation-triangle' viewBox='0 0 16 16'%3E%3Cpath d='M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.15.15 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.2.2 0 0 1-.054.06.1.1 0 0 1-.066.017H1.146a.1.1 0 0 1-.066-.017.2.2 0 0 1-.054-.06.18.18 0 0 1 .002-.183L7.884 2.073a.15.15 0 0 1 .054-.057m1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767z'/%3E%3Cpath d='M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right calc(.375em + .1875rem) center;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}

.table-buttons {
  text-align: right;
  white-space: nowrap;
}

.toast-container {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  z-index: 1070;
}

.spinner-mask {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.5);
  position: fixed;
  z-index: 10000;
}

.spinner-border {
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  position: absolute;
  z-index: 100000;
}

.modal-separator {
  border-top: 1px solid #DEE2E6;
  margin: 1rem -1rem;
}

.form-control {
  position: relative;
}

.input-datepicker {
  border: none;
  background: transparent;
  width: 100%;
  height: 100%;
  min-height: 38px;
  padding: .375rem .75rem;
  margin: 0;
  border-radius: .25rem;
}

.input-datepicker:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 .25rem rgba(13,110,253,.25);
}

.input-datepicker:disabled {
  background-color: #e9ecef;
}

.btn[data-p-disabled="true"] {
  opacity: .65;
  pointer-events: none;
}
</style>